import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import UrlSelector from "./UrlSelector";
import Popover from "@mui/material/Popover";

import { Fab, Slider, Stack } from "@mui/material";
import { LIVE_STATE } from "../utils/consts";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

const Controls = ({ playerRef, liveState }) => {
  const [values, setValues] = React.useState({ update: false });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const togglePlay = () => {
    if (playerRef.current.paused) {
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
    setValues({ ...values, update: !values.update });
  };

  const togglePip = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    } else {
      try {
        playerRef.current.requestPictureInPicture();
      } catch (err) {
        console.error(err);
      }
    }
    setValues({ ...values, update: !values.update });
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      playerRef.current.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const muteAudio = () => {
    playerRef.current.volume = 0;
    setValues({ ...values, update: !values.update });
  };

  const maxAudio = () => {
    playerRef.current.volume = 1;
    setValues({ ...values, update: !values.update });
  };

  const refresh = () => window.location.reload();

  return (
    <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
      <Toolbar>
        {liveState === LIVE_STATE.LIVE && (
          <>
            <IconButton
              color="inherit"
              aria-label="open audio controls"
              onClick={handleClick}
              title="Control audio"
            >
              <VolumeUpIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Box
                sx={{
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="column-reverse"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <IconButton onClick={muteAudio} aria-label="Mute Audio">
                    <VolumeDownIcon color="secondary" />
                  </IconButton>
                  <Slider
                    orientation="vertical"
                    aria-label="Volume"
                    valueLabelDisplay="auto"
                    value={parseInt(playerRef.current?.volume * 100, 10)}
                    onChange={(e) => {
                      if (playerRef.current) {
                        playerRef.current.volume = e.target.value / 100;
                        setValues({ ...values, update: !values.update });
                      }
                    }}
                    step={1}
                    sx={{ flex: 1 }}
                  />
                  <IconButton onClick={maxAudio} aria-label="Max volume">
                    <VolumeUpIcon color="secondary" />
                  </IconButton>
                </Stack>
              </Box>
            </Popover>
            {(document?.pictureInPictureEnabled ||
              !playerRef.current?.disablePictureInPicture) && (
              <IconButton
                color="inherit"
                onClick={togglePip}
                title="Picture in Picture Mode"
              >
                <PictureInPictureIcon />
              </IconButton>
            )}
          </>
        )}
        <StyledFab
          color="secondary"
          aria-label="add"
          disabled={liveState === LIVE_STATE.LOADING}
          onClick={liveState !== LIVE_STATE.LIVE ? refresh : togglePlay}
          title={liveState !== LIVE_STATE.LIVE ? "Refresh" : "Toggle play"}
        >
          {liveState !== LIVE_STATE.LIVE ? (
            <RefreshIcon />
          ) : (
            <>
              {!playerRef?.current?.paused ? <PauseIcon /> : <PlayArrowIcon />}
            </>
          )}
        </StyledFab>
        <Box sx={{ flexGrow: 1 }} />
        {liveState === LIVE_STATE.LIVE && (
          <IconButton
            color="inherit"
            onClick={toggleFullscreen}
            title="Fullscreen"
          >
            <FullscreenIcon />
          </IconButton>
        )}

        <UrlSelector />
      </Toolbar>
    </AppBar>
  );
};

export default Controls;
