import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { setValue } from "../utils/features/UrlSetter";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Cookies from "js-cookie";
import { HLS_STREAM_URL_COOKIE_KEY } from "../utils/consts";

export default function FormDialog() {
  const streamUrl = useSelector((state) => state.streamUrl.value);

  const [values, setValues] = React.useState({
    streamUrl: streamUrl,
    dialogOpen: false,
  });

  const dispatch = useDispatch();

  const onDialogClose = () => setValues({ ...values, dialogOpen: false });

  const saveStreamUrl = () => {
    Cookies.set(HLS_STREAM_URL_COOKIE_KEY, values.streamUrl);
    dispatch(setValue(values.streamUrl));
    setValues({ ...values, dialogOpen: false });
  };

  const setHennefStreamUrl = () => {
    setValues({
      ...values,
      streamUrl:
        "https://cdn3.wowza.com/1/TFlNWkRQSXp4VDZP/d2hWT2dM/hls/live/playlist.m3u8",
    });
  };

  const setWMStreamUrl = () => {
    setValues({
      ...values,
      streamUrl:
        "https://cdn3.wowza.com/1/K3g2UFQ1bEZkek9X/VEdiZkxh/hls/live/playlist.m3u8",
    });
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={() => setValues({ ...values, dialogOpen: true })}
      >
        <SettingsIcon />
      </IconButton>
      <Dialog
        open={values.dialogOpen}
        onClose={onDialogClose}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>Change Stream URL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter custom HLS Stream URL if you want
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="url"
            label="HLS Stream URL"
            type="url"
            fullWidth
            variant="standard"
            value={values.streamUrl}
            onChange={(e) =>
              setValues({ ...values, streamUrl: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={setWMStreamUrl}>WM</Button>
          <Button onClick={setHennefStreamUrl}>Hennef</Button>
          <Button onClick={onDialogClose}>Cancel</Button>
          <Button onClick={saveStreamUrl}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
