import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { HLS_STREAM_URL_COOKIE_KEY } from "../consts";

const initialStreamUrl =
  Cookies.get(HLS_STREAM_URL_COOKIE_KEY) ||
  process.env.REACT_APP_INITIAL_HLS_STREAM;

export const streamSlice = createSlice({
  name: "StreamUrl",
  initialState: {
    value: initialStreamUrl,
  },
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setValue } = streamSlice.actions;

export default streamSlice.reducer;
