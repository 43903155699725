import React from "react";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import PlayerView from "./views/PlayerView";
import { Provider } from "react-redux";
import store from "./utils/store";

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#3f50b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

let initialTheme = lightTheme;
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  initialTheme = darkTheme;
}

const App = () => {
  const [currentTheme, setCurrentTheme] = React.useState(initialTheme);

  React.useEffect(() => {
    const darkModeFunc = (e) => {
      e.matches ? setCurrentTheme(darkTheme) : setCurrentTheme(lightTheme);
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", darkModeFunc);
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", darkModeFunc);
    };
  }, []);
  return (
    <Provider store={store}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <PlayerView />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
