import React from "react";
import axios from "axios";
import ReactHlsPlayer from "react-hls-player";
import {
  Container,
  Card,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import Controls from "./../components/Controls";
import { LIVE_STATE, DEBUG } from "../utils/consts";
import { useSelector } from "react-redux";

const CenteredCard = ({ children }) => (
  <Card
    sx={{
      display: "flex",
      padding: "8px",
      width: "100%",
      m: "auto",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {children}
  </Card>
);

const PlayerView = () => {
  const streamUrl = useSelector((state) => state.streamUrl.value);
  const [liveState, setLiveState] = React.useState(LIVE_STATE.LOADING);
  const playerRef = React.useRef();

  React.useEffect(() => {
    axios
      .get(streamUrl)
      .then(() => {
        setLiveState(LIVE_STATE.LIVE);
      })
      .catch((err) => {
        setLiveState(LIVE_STATE.OFFLINE);
        if (DEBUG) console.error(err);
      });

    if (playerRef && playerRef.current) {
      const videoPlayer = playerRef?.current;

      const fireOnVideoEnd = () => {
        setLiveState(LIVE_STATE.ENDED);
      };
      videoPlayer.addEventListener("ended", fireOnVideoEnd);
      return () => {
        videoPlayer.removeEventListener("ended", fireOnVideoEnd);
      };
    }
    return () => {};
  }, [streamUrl, playerRef]);

  if (liveState === LIVE_STATE.LOADING) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <CenteredCard>
          <Box display="flex" justifyContent="center" flexDirection="column">
            <CircularProgress sx={{ margin: "0 auto 1rem auto" }} />
            <Typography variant="subtitle1">Stream is loading...</Typography>
          </Box>
        </CenteredCard>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        padding: 0,
      }}
    >
      <CenteredCard>
        {liveState === LIVE_STATE.LOADING && <CircularProgress />}
        <ReactHlsPlayer
          playerRef={playerRef}
          src={streamUrl}
          hlsConfig={{
            maxLoadingDelay: 4,
            minAutoBitrate: 0,
            lowLatencyMode: true,
            liveDurationInfinity: true,
            progressive: true,
            autoStartLoad: true,
            startPosition: -1,
          }}
          controls={true}
          controlsList="nodownload noplaybackrate noremoteplayback"
          width="100%"
          height="auto"
          style={{ display: liveState === LIVE_STATE.LIVE ? "block" : "none" }}
        />
        {liveState === LIVE_STATE.OFFLINE && (
          <Typography variant="subtitle1">Stream offline</Typography>
        )}
        {liveState === LIVE_STATE.ENDED && (
          <Typography variant="subtitle1">Stream ended</Typography>
        )}
      </CenteredCard>
      <Controls playerRef={playerRef} liveState={liveState} />
    </Container>
  );
};

export default PlayerView;
